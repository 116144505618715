import classNames from 'classnames';
export function getInputClassName(prefixCls, bordered, size, disabled, direction) {
    return classNames(prefixCls, {
        [`${prefixCls}-sm`]: size === 'small',
        [`${prefixCls}-lg`]: size === 'large',
        [`${prefixCls}-disabled`]: disabled,
        [`${prefixCls}-rtl`]: direction === 'rtl',
        [`${prefixCls}-borderless`]: !bordered,
    });
}
export function hasPrefixSuffix(props) {
    return !!(props.prefix || props.suffix || props.allowClear);
}
