var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import classNames from 'classnames';
import toArray from 'rc-util/lib/Children/toArray';
import { ConfigContext } from '../config-provider';
import Item from './Item';
import useFlexGapSupport from '../_util/hooks/useFlexGapSupport';
export const SpaceContext = React.createContext({
    latestIndex: 0,
    horizontalSize: 0,
    verticalSize: 0,
    supportFlexGap: false,
});
const spaceSize = {
    small: 8,
    middle: 16,
    large: 24,
};
function getNumberSize(size) {
    return typeof size === 'string' ? spaceSize[size] : size || 0;
}
const Space = props => {
    const { getPrefixCls, space, direction: directionConfig } = React.useContext(ConfigContext);
    const { size = (space === null || space === void 0 ? void 0 : space.size) || 'small', align, className, children, direction = 'horizontal', prefixCls: customizePrefixCls, split, style, wrap = false } = props, otherProps = __rest(props, ["size", "align", "className", "children", "direction", "prefixCls", "split", "style", "wrap"]);
    const supportFlexGap = useFlexGapSupport();
    const [horizontalSize, verticalSize] = React.useMemo(() => (Array.isArray(size) ? size : [size, size]).map(item => getNumberSize(item)), [size]);
    const childNodes = toArray(children, { keepEmpty: true });
    const mergedAlign = align === undefined && direction === 'horizontal' ? 'center' : align;
    const prefixCls = getPrefixCls('space', customizePrefixCls);
    const cn = classNames(prefixCls, `${prefixCls}-${direction}`, {
        [`${prefixCls}-rtl`]: directionConfig === 'rtl',
        [`${prefixCls}-align-${mergedAlign}`]: mergedAlign,
    }, className);
    const itemClassName = `${prefixCls}-item`;
    const marginDirection = directionConfig === 'rtl' ? 'marginLeft' : 'marginRight';
    // Calculate latest one
    let latestIndex = 0;
    const nodes = childNodes.map((child, i) => {
        if (child !== null && child !== undefined) {
            latestIndex = i;
        }
        /* eslint-disable react/no-array-index-key */
        return (<Item className={itemClassName} key={`${itemClassName}-${i}`} direction={direction} index={i} marginDirection={marginDirection} split={split} wrap={wrap}>
        {child}
      </Item>);
        /* eslint-enable */
    });
    const spaceContext = React.useMemo(() => ({ horizontalSize, verticalSize, latestIndex, supportFlexGap }), [horizontalSize, verticalSize, latestIndex, supportFlexGap]);
    // =========================== Render ===========================
    if (childNodes.length === 0) {
        return null;
    }
    const gapStyle = {};
    if (wrap) {
        gapStyle.flexWrap = 'wrap';
        // Patch for gap not support
        if (!supportFlexGap) {
            gapStyle.marginBottom = -verticalSize;
        }
    }
    if (supportFlexGap) {
        gapStyle.columnGap = horizontalSize;
        gapStyle.rowGap = verticalSize;
    }
    return (<div className={cn} style={Object.assign(Object.assign({}, gapStyle), style)} {...otherProps}>
      <SpaceContext.Provider value={spaceContext}>{nodes}</SpaceContext.Provider>
    </div>);
};
export default Space;
